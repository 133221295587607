import DropdownMenuExport2 from "../components/DropdownMenuExport2";
import { utils, writeFileXLSX } from "xlsx";
import Pagination from "./Pagination";
import FilterByDate from "./FilterByDate";
import DropdownMenuExport from "./DropdownMenuExport";

const CustomerDetail = ({
  detailCustomer,
  tabelDetailCustomer,
  tableDetailCount,
  sumGrandTotal,
  modalDetail,
  dateStart,
  setDateStart,
  dateEnd,
  setDateEnd,
  maxPage,
  currentPage,
  showTablePage,
  exportDetail,
  printDetail,
}) => {
  return (
    <div className="flex flex-col h-full font-noto-sans">
      <div className="bg-white rounded-lg overflow-hidden flex h-full flex-col gap-4 p-5">
        <div className="flex flex-row justify-between py-2">
          <div className="flex flex-col">
            <h1 className="text-black font-bold text-lg mt-1 mb-2 mr-20">
              {detailCustomer.name}
            </h1>
            <h1 className="text-gray-400 font-semibold text-md">
              {detailCustomer.code}
            </h1>
            <p className="w-full text-black mt-2">
              <strong>Total Expanse : {sumGrandTotal} </strong>
            </p>
            <div className="mt-2 flex space-x-4">
              <div className="flex">
                <FilterByDate
                  dateStart={dateStart}
                  setDateStart={setDateStart}
                  dateEnd={dateEnd}
                  setDateEnd={setDateEnd}
                />
              </div>
              <div className="flex">
                {/* aktifkan jika diminta menambahkan export riwayat transaksi customer */}
                <DropdownMenuExport export={exportDetail} print={printDetail} />
              </div>
            </div>
          </div>

          <div>
            <div className="flex flex-row items-center">
              <h1 className="text-black font-bold text-lg mr-2">
                {detailCustomer.membership.toUpperCase()}
              </h1>
            </div>
          </div>
        </div>

        <p className="w-full text-black">
          Total List Expanse : {tableDetailCount}
        </p>

        <div
          id="printArea"
          className="bg-white relative rounded-lg overflow-y-auto scrollbar-shown h-full flex flex-col mb-8"
        >
          <table className="font-nunito-sans text-xs w-full overflow-y-auto relative">
            <thead className="sticky top-0">
              <tr className="bg-[#F9F9FC] text-black text-left">
                <th className="p-2 whitespace-nowrap">Datetime</th>
                <th className="p-2 whitespace-nowrap">Total</th>
                <th className="p-2 whitespace-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              {tabelDetailCustomer?.map((data) => (
                <tr className="even:bg-[#F9F9FC] text-black" key={data.id}>
                  <td className="p-2 whitespace-nowrap">{data.datetime}</td>
                  <td className="p-2 whitespace-nowrap">{data.grand_total}</td>
                  <td className="p-2 whitespace-nowrap">
                    <button
                      className="bg-blue-200 px-3 py-2 rounded-lg"
                      onClick={() => modalDetail(data.id)}
                    >
                      Detail
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {tabelDetailCustomer.length > 0 && (
          <Pagination
            maxPage={maxPage}
            currentPage={currentPage}
            showTablePage={showTablePage}
          />
        )}
      </div>
    </div>
  );
};

export default CustomerDetail;
