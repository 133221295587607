import axios from "axios";
import { useEffect, useState } from "react";
import DropdownMenuExport from "../components/DropdownMenuExport";
import Pagination from "../components/Pagination";
import ReportNavLink from "../components/ReportNavLink";
import Search from "../components/Search";
import TableReportTransaction from "../components/TableReportTransaction";
import TabTitle from "../utils/GeneralFunction";
import Session from "../Session";
import FilterByDate from "../components/FilterByDate";
import { utils, writeFileXLSX } from "xlsx";
import ModalAlert from "../components/ModalAlert";
import DropdownSelect from "../components/DropdownSelect";
import DropdownOption from "../components/DropdownOption";
import PrintReport from "../utils/PrintReport";
import { API_URL } from '../utils/api';
const XLSX = require('xlsx');

const ReportTransaction = () => {
  TabTitle("Transaction - Kato Haircut");
  // Modal
  const [openAlert, setOpenAlert] = useState(false);
  const closeAlertModal = () => {
    setOpenAlert(false);
    setErrorMsg("");
  };
  const [errorMsg, setErrorMsg] = useState("");
  // Table & Pagination
  const [tableData, setTableData] = useState([]);
  const [tableCount, setTableCount] = useState(null);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [searchType, setSearchType] = useState("Transaction ID");
  const [searchValue, setSearchValue] = useState("");
  console.log(searchType, searchValue);

  const [revenue, setRevenue] = useState();
  const [profit, setProfit] = useState();
  const [commission, setCommission] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");

  // const fetchData = async (
  //   page = currentTablePage,
  //   search = "",
  //   dateStart,
  //   dateEnd
  // ) => {
  //   try {
  //     const {data} = await axios.get(
  //       `${API_URL}transactions${
  //         search !== "" && search !== undefined
  //           ? searchType === "Transaction ID"
  //             ? page !== "" && page !== undefined
  //               ? `?searchCode=${search}&page=${page}`
  //               : `?searchCode=${search}`
  //             : searchType === "Category Service"
  //             ? page !== "" && page !== undefined
  //               ? `?searchCategoryName=${search}&page=${page}`
  //               : `?searchCategoryName=${search}`
  //             : searchType === "Product Name"
  //             ? page !== "" && page !== undefined
  //               ? `?searchProductName=${search}&page=${page}`
  //               : `?searchProductName=${search}`
  //             : page !== "" && page !== undefined
  //             ? `?page=${page}`
  //             : ``
  //           : dateStart !== "" && dateStart !== undefined
  //           ? dateEnd !== "" && dateEnd !== undefined
  //             ? page !== "" && page !== undefined
  //               ? `?from=${dateStart}&to=${dateEnd}&page=${page}`
  //               : `?from=${dateStart}&to=${dateEnd}`
  //             : page !== "" && page !== undefined
  //             ? `?from=${dateStart}&page=${page}`
  //             : `?from=${dateStart}`
  //           : dateEnd !== "" && dateEnd !== undefined
  //           ? page !== "" && page !== undefined
  //             ? `?to=${dateEnd}&page=${page}`
  //             : `?to=${dateEnd}`
  //           : page !== "" && page !== undefined
  //           ? `?page=${page}`
  //           : ``
  //       }`,
  //       Session()
  //     );
  //     setTableData(data.data);
  //     setTableCount(data.meta.total);
  //     setRevenue(data.total_revenue);
  //     setProfit(data.total_profit);
  //     setCommission(data.total_comission);
  //     setItemsPerPage(data.meta.per_page);
  //   } catch (err) {
  //     if (!err?.response) {
  //       setErrorMsg("No Server Response");
  //     } else if (err.response?.status === 401) {
  //       setErrorMsg("Unauthorized, please login again!");
  //     } else {
  //       setErrorMsg("Can't get data");
  //     }
  //     setOpenAlert(true);
  //   }
  // };

  const fetchData = async (
    page = currentTablePage,
    search = "",
    dateStart = "",
    dateEnd = ""
  ) => {
    try {
      let url = `${API_URL}transactions?`;

      if (search !== "") {
        if (searchType === "Transaction ID") {
          url += `searchCode=${search}`;
        } else if (searchType === "Category Service") {
          url += `searchCategoryName=${search}`;
        } else if (searchType === "Product Name") {
          url += `searchProductName=${search}`;
        }
      }

      if (dateStart !== "" && dateEnd !== "") {
        if (search !== "") {
          url += "&";
        }
        url += `from=${dateStart}&to=${dateEnd}`;
      }

      if (page !== "" && page !== undefined) {
        if (search !== "" || dateStart !== "" || dateEnd !== "") {
          url += "&";
        }
        url += `page=${page}`;
      }
      // console.log(url); 
      const { data } = await axios.get(url, Session());
      // console.log(data.data); // Log the server response
      setTableData(data.data);
      setTableCount(data.meta.total);
      setRevenue(data.total_revenue);
      setProfit(data.total_profit);
      setCommission(data.total_comission);
      setItemsPerPage(data.meta.per_page);
    } catch (err) {
      console.error("Error fetching data:", err);
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrorMsg("Unauthorized, please login again!");
      } else {
        setErrorMsg("Can't get data");
      }
      setOpenAlert(true);
    }
  };

  const showTablePage = (page) => {
    setCurrentTablePage(page);
    fetchData(page, searchValue, dateStart, dateEnd);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const exportAll = async () => {
    try {
      // Get data from the API
      let { data } = await axios.get(
        `${API_URL}transactions${searchValue !== "" && searchValue !== undefined
          ? searchType === "Transaction ID"
            ? `?searchCode=${searchValue}&paginate=false`
            : searchType === "Product Name"
              ? `?searchProductName=${searchValue}&paginate=false`
              : searchType === "Category Service"
                ? `?searchCategoryName=${searchValue}&paginate=false`
                : `?paginate=false`
          : dateStart !== "" && dateStart !== undefined
            ? dateEnd !== "" && dateEnd !== undefined
              ? `?from=${dateStart}&to=${dateEnd}&paginate=false`
              : `?paginate=false`
            : `?paginate=false`
        }`,
        Session()
      );

      let temp = data.data.map((data, index) => [
        index + 1,
        data.code,
        data.product_name,
        data.category_name,
        data.qty,
        data.price,
        data.discount,
        data.price_after_discount,
        data.total_fee,
        data.profit,
        data.datetime,
      ]);
      const headings = [
        [
          "No",
          "Transaction ID",
          "Product Name",
          "Category Name",
          "Quantity",
          "Price",
          "Discount",
          "Price After Discount",
          "Commission",
          "Profit",
          "Date & Time",
        ],
      ];
      // const wb = utils.book_new();
      // const ws = utils.json_to_sheet(temp);
      // utils.sheet_add_aoa(ws, headings);
      // utils.sheet_add_json(ws, temp, {origin: "A4", skipHeader: true});
      // utils.book_append_sheet(wb, ws, "Report Data");
      // writeFileXLSX(wb, "Report Transaction Data.xlsx");

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(temp);

      XLSX.utils.sheet_add_aoa(ws, headings);
      ws["!cols"] = [{ wch: 15 }];
      XLSX.utils.book_append_sheet(wb, ws, "Report Data");
      XLSX.writeFile(wb, "Report Commission Data.xlsx");

    } catch (error) {
      console.error("Error exporting data:", error);
    }


  };

  const printAll = async () => {
    // let {data} = await axios.get(
    //   `${API_URL}transactions${
    //     dateStart !== "" && dateStart !== undefined
    //       ? dateEnd !== "" && dateEnd !== undefined
    //         ? `?from=${dateStart}&to=${dateEnd}&paginate=false`
    //         : `?paginate=false`
    //       : `?paginate=false`
    //   }`,
    //   Session()
    // );

    let params = "?paginate=false";

    if (dateStart !== "" && dateStart !== undefined) {
      params += `&from=${dateStart}`;
      if (dateEnd !== "" && dateEnd !== undefined) {
        params += `&to=${dateEnd}`;
      }
    }

    if (searchValue !== "" && searchValue !== undefined) {
      if (searchType === "Transaction ID") {
        params += `&searchCode=${searchValue}`;
      } else if (searchType === "Category Service") {
        params += `&searchCategoryName=${searchValue}`;
      } else if (searchType === "Product Name") {
        params += `&searchProductName=${searchValue}`;
      }
    }

    let { data } = await axios.get(`${API_URL}transactions${params}`, Session());


    let temp = data.data.map((data, index) => [
      index + 1,
      data.datetime,
      data.code,
      data.product_name,
      data.category_name,
      data.qty,
      data.price,
      data.discount,
      data.price_after_discount,
      data.total_fee,
      data.profit,
    ]);
    PrintReport(
      false,
      `Transaction Report`,
      "TRANSACTIONS REPORT",
      ["Total Revenue", "Total Profit"],
      [data.total_revenue, data.total_profit],
      [
        "No",
        "Date",
        "Transaction ID",
        "Product Name",
        "Category Service",
        "Qty",
        "Price",
        "Discount",
        "Price After Discount",
        "Total Commission",
        "Total Profit",
      ],
      temp
    );
  };

  const closeAll = () => { };

  const handleSearchValue = (val) => {
    setSearchValue(val);
    setCurrentTablePage(1);
    // setDateStart("");
    // setDateEnd("");
    fetchData(1, val, dateStart, dateEnd);
  };

  const handleFilterDateStart = (val) => {
    setDateStart(val);
    // setCurrentTablePage(1);
    // setSearchValue("");
    fetchData(1, searchValue, val, dateEnd);
  };

  const handleFilterDateEnd = (val) => {
    setDateEnd(val);
    // setCurrentTablePage(1);
    // setSearchValue("");
    fetchData(1, searchValue, dateStart, val);
  };

  return (
    <div className="flex flex-col h-full font-noto-sans">
      <ModalAlert show={openAlert} close={closeAlertModal} message={errorMsg} />
      <div className="flex flex-col md:flex-row overflow-y-hidden overflow-x-auto scrollbar-hide min-h-[3rem]">
        <ReportNavLink />
      </div>
      <div className="w-full flex flex-col mt-3 md:flex-row grow overflow-auto scrollbar-shown">
        <div className="basis-full md:basis-2/2 lg:basis-6/6">
          <div className="bg-white relative rounded-lg overflow-hidden flex h-full flex-col p-3">
            <div className="flex flex-row my-2 justify-between">
              <div className="flex flex-col gap-y-5 sm:flex-row sm:gap-x-5">
                <div className="flex flex-col">
                  <h1 className="text-black">Grand Total Revenue</h1>
                  <h1 className="text-green-500 font-semibold text-2xl">
                    Rp{revenue}
                  </h1>
                </div>
                <div className="flex flex-col">
                  <h1 className="text-black">Grand Total Profit</h1>
                  <h1 className="text-green-500 font-semibold text-2xl">
                    Rp{profit}
                  </h1>
                </div>
                <div className="flex flex-col">
                  <h1 className="text-black">Grand Total Commission</h1>
                  <h1 className="text-green-500 font-semibold text-2xl">
                    Rp{commission}
                  </h1>
                </div>
              </div>
              <DropdownMenuExport
                export={exportAll}
                print={printAll}
                close={closeAll}
              />
            </div>
            <div className="flex flex-col xl:flex-row xl:gap-2 border-t pt-4 my-2">
              <div className="flex flex-row gap-2">
                <div className="text-black">
                  <DropdownSelect
                    header={
                      searchType !== "" && searchType !== undefined
                        ? searchType
                        : "Select Comparison..."
                    }
                    showExpandIcon={true}
                    headerClass="px-2 py-2 border rounded-lg flex flex-row items-center gap-0.5 whitespace-nowrap"
                  >
                    <DropdownOption
                      target={searchType}
                      setTarget={setSearchType}
                      value={"Transaction ID"}
                    />
                    <DropdownOption
                      target={searchType}
                      setTarget={setSearchType}
                      value={"Category Service"}
                    />
                    <DropdownOption
                      target={searchType}
                      setTarget={setSearchType}
                      value={"Product Name"}
                    />
                  </DropdownSelect>
                </div>
                <Search
                  textColor={"text-black"}
                  bgColor={"bg-white"}
                  placeholder={`Search by ${searchType === "Transaction ID"
                      ? "transaction id"
                      : searchType === "Category Service"
                        ? "category service"
                        : "product name"
                    }...`}
                  searchValue={searchValue}
                  setSearchValue={handleSearchValue}
                />
              </div>
              <FilterByDate
                dateStart={dateStart}
                setDateStart={handleFilterDateStart}
                dateEnd={dateEnd}
                setDateEnd={handleFilterDateEnd}
              />
            </div>

            <p className="w-full text-black mb-4">Total All Transaction : {tableCount} </p>

            {tableData[0] ? (
              <>
                <div
                  id="printArea"
                  className="bg-white relative rounded-lg overflow-y-auto scrollbar-shown flex h-full flex-col my-2"
                >
                  <TableReportTransaction
                    tableData={tableData}
                    currentPage={currentTablePage}
                  />
                </div>
                <Pagination
                  maxPage={Math.ceil(tableCount / itemsPerPage)}
                  currentPage={currentTablePage}
                  showTablePage={showTablePage}
                />
              </>
            ) : (
              <p className="w-full text-black">No result</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportTransaction;
